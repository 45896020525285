<template>
    <v-container class="py-8 px-6 pl-12" fluid>

        <tile-floating-title @submit="doSubmit" v-if="showFloating"></tile-floating-title>

        <v-row class="mt-16 following-row" v-if="loaded">
            <v-col class="mb-0">
                <tile-work-time :ref="'tile_work_time'"></tile-work-time>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="mb-0" v-if="loaded">
                <tile-intro-ment :ref="'tile_intro_ment'"></tile-intro-ment>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="mb-0" v-if="loaded">
                <tile-work-off-ment :ref="'tile_work_off_ment'"></tile-work-off-ment>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="mb-0" v-if="loaded">
                <tile-break-time :ref="'tile_break_time'"></tile-break-time>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {mapState} from 'vuex'

export default {
    name: 'svc_voice_intro',
    components: {
        'tileFloatingTitle': () => import('@/components/common/tile_floating_title.vue'),
        'tileWorkTime': () => import('@/components/svc/voice_intro/tile_work_time.vue'),
        'tileIntroMent': () => import('@/components/svc/voice_intro/tile_intro_ment.vue'),
        'tileWorkOffMent': () => import('@/components/svc/voice_intro/tile_work_off_ment.vue'),
        'tileBreakTime': () => import('@/components/svc/voice_intro/tile_break_time.vue'),
    },
    computed: {
        ...mapState('svcVoiceIntro', {
            breakTimeConf: state => state.breakTimeConf
        })
    },
    data: () => ({
        loaded: false,
        showFloating: false
    }),
    created() {
        this.$store.commit('layoutFlag', true)
        this.$store.commit('menuId', 'svc_voice_intro')
        this.$store.commit('svcVoiceIntro/setFloatingOptions', {
            title: '인사말&영업시간',
                page: 'svc_voice_intro',
                css: {
                pixedWidth: -24,
                pixedMarginLeft: 0
            }
        },)
        this.doSearch()
    },
    mounted() {
        this.showFloating = true
    },
    methods: {
        doSearch() {
            let ctx = this
            this.$store.dispatch('svcVoiceIntro/getConfig', {
                scb: (va) => {
                    va.loaded = true
                    if (ctx.$refs.tile_work_time) ctx.$refs.tile_work_time.reload()
                    if (ctx.$refs.tile_intro_ment) ctx.$refs.tile_intro_ment.reload()
                    if (ctx.$refs.tile_work_off_ment) ctx.$refs.tile_work_off_ment.reload()
                    if (ctx.$refs.tile_break_time) ctx.$refs.tile_break_time.reload()
                }, fcb: (err, va) => {
                    va.loaded = true
                    if (this.$noSessionProc(err)) {
                    }
                }, va: this
            })
        },
        doCancel() {
            this.$store.dispatch('openConfirm', {
                message: '변경된 내용을 취소하시겠습니까?',
                va: this,
                okCb: function (va) {
                    va.doSearch()
                }
            })
        },
        doSubmit() {

            if (this.breakTimeConf.breakTimes && this.breakTimeConf.breakTimes.length != 0) {
                this.breakTimeConf.breakTimes.forEach((item, idx) => {
                    this.breakTimeConf.breakTimes[idx] = Object.assign(item, {'break_start': item.break_start_hour + item.break_start_min, 'break_end': item.break_end_hour + item.break_end_min})
                })
            }

            this.$store.dispatch('openConfirm', {
                message: '현재 내용으로 저장하시겠습니까?',
                va: this,
                okCb: function (va) {
                    va.doSubmitProc()
                }
            })
        },
        doSubmitProc() {
            this.$refs.tile_work_time.apply()
            this.$refs.tile_intro_ment.apply()
            this.$refs.tile_work_off_ment.apply()
            this.$refs.tile_break_time.apply()

            let va = this
            this.$store.dispatch('svcVoiceIntro/doSubmit', {
                scb: () => {
                    va.$store.dispatch('openAlert', {
                        va: va,
                        message: '저장되었습니다',
                        closeCb: () => {
                            va.doSearch()
                        }
                    })

                }, fcb: (err) => {
                    if (this.$noSessionProc(err)) {
                        return
                    }
                    va.$store.dispatch('openAlert', {
                        message: '저장을 실패 했습니다',
                        sub_message: err
                    })
                }
            })
        }
    }
}
</script>